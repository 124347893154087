import { render, staticRenderFns } from "./setwx.vue?vue&type=template&id=22c53296&scoped=true&"
import script from "./setwx.vue?vue&type=script&lang=js&"
export * from "./setwx.vue?vue&type=script&lang=js&"
import style0 from "./setwx.vue?vue&type=style&index=0&id=22c53296&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "22c53296",
  null
  
)

export default component.exports