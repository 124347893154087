<template>
  <div class="receipt">
    <div class="tips-text">
        该账号已绑定微信号，是否进行解绑？
    </div>
    <div class="btn-box">
      <div class="btn-inner">
        <div class="btn-item" @click="removeOpenIdUserChange()">确认解绑</div>
        <div class="btn-item btn-item1" @click="goFn()">取消</div>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast , Dialog } from "vant";
import Cookies from "js-cookie";
export default {
  name: "index",
  data() {
    return {
    };
  },
  methods: {
    // 取消绑定
    async removeOpenIdUser() {
      // Dialog.confirm({
      //   title: '',
      //   message: '确定取消会议',
      // })
      //   .then(() => {
          // on confirm
          this.removeOpenIdUserChange()
        // })
        // .catch(() => {
        //   // on cancel
        // });
     
    },
    async removeOpenIdUserChange() {
      Toast.loading();
      var openId = Cookies.get("openid");
      let params = {
        openId: openId,
      };
      await this.$post(`/h5/weChat/removeOpenIdUser`, params).then(
        (res) => {
          if (res.code == "0") {
              Toast(res.message);
              setTimeout(() => {
                this.$router.push({ path: "/usercenter" });
              }, 1000);
          }else{
            Toast(res.message);
          }
        }
      );
    },
    goFn(){
      this.$router.go(-1)
    }
  },
  mounted() {

  },
  components: {
    [Toast.name]: Toast,
    [Dialog.name]: Dialog,
  },
};
</script>

<style lang="scss" scoped>
.receipt {
  background: #f4f4f4;
  min-height: 100vh;
  overflow: hidden;
  .tips-text{
    font-size: 16px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #4D4D4D;
    margin: 15px;
    background: #fff;
    text-align: center;
    box-shadow: 0px 11px 16px 0px rgba(0,0,0,0.02);
    border-radius: 10px;
    padding: 25px 0;
  }
}


</style>